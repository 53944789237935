<template>
  <div data-test-id="keycard_view_link-existing">
    <div class="d-flex mb-3">
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
        @clickbutton="goToInitialStep"
      >
        <ui-icon
          :icon="ICONS.arrowBack"
          :size="ICONS_SIZES.small"
          :color="COLORS.primary"
          class="cursor-pointer mr-auto"
          hover
          data-test-id="arrow_back-icon"
        />
      </ui-button>
    </div>

    <h2
      class="mb-3 ml-3 emobg-font-large emobg-color-secondary"
      v-html="linkCustomKeycardTitle"
    />

    <p
      :class="[
        'font-regular emobg-font-medium emobg-color-ink ml-3',
        selectedKeyCard.is_bank_card ? 'mb-4' : 'mb-6',
      ]"
      v-html="$t('complete_documentation.new_custom_badge_flow.link_custom_keycard.text')"
    />

    <AlertComponent
      v-if="selectedKeyCard.is_bank_card"
      :type="ALERT_TYPES.informative"
      class="mb-6 ml-3"
    >
      {{ $t('complete_documentation.new_custom_badge_flow.link_custom_keycard.notification_about_credit_card_keycard') }}
    </AlertComponent>

    <ui-button
      v-bind="fetchButtonSpecs()"
      :loading="isLoading"
      data-test-id="keycard_button-confirm"
      class="ml-3"
      @clickbutton="onClickLinkCustomBadge"
    >
      {{ $t('complete_documentation.new_custom_badge_flow.link_custom_keycard.confirm_keycard_button') }}
    </ui-button>
  </div>
</template>

<script>
import get from 'lodash/get';
import AlertComponent from '@/components/Alert/AlertComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';

import { GTM_MODAL_EVENTS } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useTheme } from '@/composable/Theme/useTheme';

import {
  goToInitialStep,
  linkingKeycardError,
  selectedKeyCard,
  submitKeycardFormLinkCard,
} from '../KeyCardMapper';

export default {
  name: 'LinkExisting',

  components: {
    AlertComponent,
  },

  setup() {
    const { trackPageView, trackModalView } = useTrackingGTM();
    const { fetchButtonSpecs } = useTheme();

    return { trackPageView, trackModalView, fetchButtonSpecs };
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    selectedKeyCard,
    linkingKeycardError,

    linkCustomKeycardTitle() {
      return this.$t('complete_documentation.new_custom_badge_flow.link_custom_keycard.title', {
        name: get(this.selectedKeyCard, 'name'),
      });
    },
  },

  created() {
    this.trackModalView({ modalName: GTM_MODAL_EVENTS.keycardViewLinkExisting });
    this.ALERT_TYPES = ALERT_TYPES;
  },

  methods: {
    goToInitialStep,
    submitKeycardFormLinkCard,

    async onClickLinkCustomBadge() {
      // TODO: !! Create a Status prop in the Store state >:|
      this.isLoading = true;

      try {
        await this.submitKeycardFormLinkCard();

        if (this.linkingKeycardError) {
          throw new Error(this.linkingKeycardError);
        }
        this.$emit('on:keycard-linked');
      } catch (error) {
        this.$emit('on:error', error);
      }

      this.trackPageView();
      this.isLoading = false;
      this.$emit('on:close');
    },
  },
};
</script>
