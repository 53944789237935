import nth from 'lodash/nth';
import get from 'lodash/get';
import size from 'lodash/size';
import take from 'lodash/take';
import concat from 'lodash/concat';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';

import config from '@/config';

import { GTM_VIEW_ITEM_LIST_EVENT_KEYS } from '@/constants/gtm';
import { LOCATION_KEYS } from '@/constants/location.const';
import { VEHICLE_KEYS } from '@/constants/vehicle.const';

const GTMEventMapVehicleKeys = {
  [VEHICLE_KEYS.uuid]: GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemId,
  [VEHICLE_KEYS.model]: GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemName,
  [VEHICLE_KEYS.category]: GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemCategory,
  [VEHICLE_KEYS.price]: GTM_VIEW_ITEM_LIST_EVENT_KEYS.price,
};

const GTMEventMapLocationKeys = {
  [LOCATION_KEYS.uuid]: GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemListId,
  [LOCATION_KEYS.name]: GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemListName,
};

const requiredVehicleArgs = [
  VEHICLE_KEYS.uuid,
  VEHICLE_KEYS.model,
  VEHICLE_KEYS.category,
];
const requiredLocationArgs = [LOCATION_KEYS.uuid, LOCATION_KEYS.name];

const requiredItemsCardinal = 5;

/**
 * @function mapLocationData
 * @desc It maps the data from location api model to GTM event keys
 *
 * @param {Object} location
 * @example <caption>Example usage of mapLocationData.</caption>
 * // returns '
 * //  {
 * //   item_list_id: 'ab06c618-6148-4904-aae3-b0498a901aec',
 * //   item_list_name: 'Plaça d'Urquinaona, 7 'Parking SABA'',
 * //   item_category3: 'Plaça d'Urquinaona, 7 'Parking SABA'',
 * //  }
 * mapLocationData({location: { uuid: 'fake-location-uuid', name: 'location-name' }  })
 * @returns {Object}
 */
export const mapLocationData = location => ({
  ...mapKeys(
    pick(location, requiredLocationArgs),
    (_value, key) => GTMEventMapLocationKeys[key],
  ),
  [GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemCategory3]: get(location, 'name'),
});

/**
 * @function mapVehiclesData
 * @desc It maps the data from vehicle api model to GTM event keys
 *
 * @param {Object} locations
 * @param {Object[]} vehicles
 * @param {Integer} currentIndex
 * @param {String} city
 * @param {Number} bookingHours
 * @example <caption>Example usage of mapVehiclesData.</caption>
 * // returns '
 * // {
 * //  {
 * //   item_list_id: 'ab06c618-6148-4904-aae3-b0498a901aec',
 * //   item_list_name: 'Plaça d'Urquinaona, 7 'Parking SABA'',
 * //   item_category3: 'Plaça d'Urquinaona, 7 'Parking SABA'',
 * //   item_id: '1e24e246-89d3-4ecf-ba45-9c8c90998ee0',
 * //   item_name: 'Volkswagen T-CROSS 1.0 TSI',
 * //   item_category: 'Large',
 * //   index: 4,
 * //   item_brand: 'Ubeeqo',
 * //   price: 2.9,
 * //   item_category_2: 'Barcelona'
 * //  }
 * // ]
 * mapVehiclesData({location: { uuid: 'fake-location-uuid' }, vehicles: [{ uuid: 'vehicle-uuid' }], currentIndex: 0 , city: 'Caladan'  })
 * @returns {Object[]}
 */
export const mapVehiclesData = ({
  city,
  location = {},
  vehicles = [],
  currentIndex = 0,
  bookingHours,
  discount,
}) => {
  let index = currentIndex;
  return map(vehicles, vehicle => {
    const vehicleIndex = index;
    index += 1;
    const mappedVehicle = mapKeys(
      pick(vehicle, requiredVehicleArgs),
      (_value, key) => GTMEventMapVehicleKeys[key],
    );
    return {
      ...location,
      ...mappedVehicle,
      [GTM_VIEW_ITEM_LIST_EVENT_KEYS.index]: vehicleIndex,
      [GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemBrand]: get(config, 'data.appName'),
      [GTM_VIEW_ITEM_LIST_EVENT_KEYS.itemCategory2]: city,
      [GTM_VIEW_ITEM_LIST_EVENT_KEYS.price]: parseFloat(
        get(vehicle, 'tariff.hourly_price_raw') / 100,
      ),
      ...(isNil(bookingHours) ? {} : { quantity: bookingHours }),
      ...(isNil(discount) ? {} : { discount }),
    };
  });
};

/**
 * @function composeViewItemListData
 * @desc It maps the data from api models to GTM event keys
 *
 * @param {Object[]} locations
 * @example <caption>Example usage of composeViewItemListData.</caption>
 * // returns '
 * // {
 * //  {
 * //   currency: 'EUR',
 * //   items: [
 * //     item_list_id: 'ab06c618-6148-4904-aae3-b0498a901aec',
 * //     item_list_name: 'Plaça d'Urquinaona, 7 'Parking SABA'',
 * //     item_category3: 'Plaça d'Urquinaona, 7 'Parking SABA'',
 * //     item_id: '1e24e246-89d3-4ecf-ba45-9c8c90998ee0',
 * //     item_name: 'Volkswagen T-CROSS 1.0 TSI',
 * //     item_category: 'Large',
 * //     index: 4,
 * //     item_brand: 'Ubeeqo',
 * //     price: 2.9,
 * //     item_category_2: 'Barcelona'
 * //   ]
 * //  }
 * // ]
 * composeViewItemListData([{ uuid: 'fake-uuid', vehicles: [{uuid: 'fake-vehicle-uuid'...}] }, { uuid: 'fake-uuid-2', vehicles: [{}] } ])
 * @returns {Object[]]}
 */
export const composeViewItemListData = ({ city, currency, locations = [] }) => {
  let items = [];

  let stepper = 0;
  let currentSize = size(items);
  do {
    const location = nth(locations, stepper);
    const mappedLocation = mapLocationData(location);
    const missingItemsCardinal = requiredItemsCardinal - currentSize;
    const locationVehicles = take(get(location, 'vehicles'), missingItemsCardinal);
    const mappedVehicles = mapVehiclesData({
      location: mappedLocation,
      vehicles: locationVehicles,
      currentIndex: currentSize,
      city,
    });

    items = concat(items, mappedVehicles);
    currentSize = size(items);
    stepper += 1;
  } while (currentSize < requiredItemsCardinal && !isNil(nth(locations, stepper)));

  return {
    currency,
    items,
  };
};
